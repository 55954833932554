import React from 'react'
import Search from '../../containers/Search'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useTranslation } from 'next-i18next'
import { searchBoxParametersAtom } from '../../utils/atoms'
import { useAtom } from 'jotai'

function SearchTabs() {
    const { t, ready } = useTranslation()
    const [searchBoxParameters, setSearchBoxParameters] = useAtom(searchBoxParametersAtom)

    const preSelectedVehicle = searchBoxParameters.vType || 'Car'
    const defIndex =
    preSelectedVehicle === 'Moto' ? 1 : preSelectedVehicle === 'ATV' ? 2 : 0

    const handleTabSelect = (index) => {
        let newVtype
        switch (index) {
        case 0:
            newVtype = 'Car'
            break
        case 1:
            newVtype = 'Moto'
            break
        case 2:
            newVtype = 'Atv'
            break
        default:
            newVtype = 'Car'
        }

        setSearchBoxParameters((prev) => ({
            ...prev,
            vType: newVtype,
        }))
    }

    return (
        <>
            {ready && (
                <Tabs defaultIndex={defIndex} onSelect={handleTabSelect}>
                    <TabList>
                        <Tab>{t('CARS')}</Tab>
                        <Tab>{t('MOTOS')}</Tab>
                        <Tab>{t('ATVs')}</Tab>
                    </TabList>
                    <TabPanel>
                        <Search type="Car" />
                    </TabPanel>
                    <TabPanel>
                        <Search type="Moto" />
                    </TabPanel>
                    <TabPanel>
                        <Search type="ATV" />
                    </TabPanel>
                </Tabs>
            )}
        </>
    )
}

export default SearchTabs
