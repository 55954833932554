import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'next-i18next'
import { Transmission } from '../../types/backendModels/availability'


interface FormTransmissionProps {
    setTransmission: (transmission: Transmission) => void
    transmissionType: Transmission
}

const FormTransmission = ({ setTransmission, transmissionType }:FormTransmissionProps) => {
    const { t } = useTranslation()

    const transmissionOptions = [
        { value: 'manual', label: t('Manual') },
        { value: 'auto', label: t('Auto') },
    ]

    const selectedTransmissionType =
    transmissionOptions.find((option) => option.value === transmissionType) || null

    const onChange = (selectedOption) => {
        if (selectedOption) {
            setTransmission(selectedOption.value)
        }
    }

    return (
        <div className="categories_container">
            <Select
                value={selectedTransmissionType}
                onChange={onChange}
                options={transmissionOptions}
                className=""
                placeholder={t('Transmission Type')}
            />
        </div>
    )
}

export default FormTransmission
