import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'next-i18next'
import { Fuel } from '../../types/backendModels/availability'


interface FormFuelProps {
    setFuel: (fuel: Fuel) => void
    fuel: Fuel
}

const FormFuel = ({ setFuel, fuel }:FormFuelProps) => {
    const { t } = useTranslation()

    const fuelOptions = [
        { value: 'benzine', label: t('Benzine') },
        { value: 'diesel', label: t('Diesel') },
        { value: 'LPG', label: t('LPG') },
        { value: 'gas', label: t('Gas') },
        { value: 'electric', label: t('Electric') },
        { value: 'hybrid', label: t('Hybrid') },
    ]


    const onChange = (selectedOption) => {
        if (selectedOption) {
            setFuel(selectedOption.value)
        }
    }

    const fuelType = fuelOptions.find((option) => option.value === fuel) || null
    return (
        <div className="categories_container">
            <Select
                value={fuelType}
                onChange={onChange}
                options={fuelOptions}
                className=""
                placeholder={t('Fuel Type')}
            />
        </div>
    )
}

export default FormFuel
