import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'

// Initialize plugins
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)

export function getInitialDate() {
    const date = new Date()
    const greekTime = dayjs(date).tz('Europe/Athens')
    const hours = greekTime.hour()
    return hours < 12 ? greekTime.add(1, 'day').toDate() : greekTime.add(2, 'day').toDate()
}

export function getDropOffMinDate(startDate: Date | string | null) {
    const date = new Date()
    if (startDate == null) {
        return dayjs(date).add(24, 'hour').toDate()
    } else {
        const startDateObj = typeof startDate === 'string' ? new Date(startDate) : startDate
        return dayjs(startDateObj).add(24, 'hour').toDate()
    }
}

export function getDateDifference(startDate: Date, endDate: Date) {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    return Math.round(end.diff(start, 'day', true))
}

export function getDateDifferenceStrings(startDate: string, endDate: string) {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    return Math.round(end.diff(start, 'day', true))
}

export function getDaysLiteralString(startDate: string, endDate: string) {

    if (getDateDifferenceStrings(startDate, endDate) === 1) {
        return 'Day'
    }
    return 'Days'
}
export function getDaysLiteralDate(startDate: Date, endDate: Date) {

    if (getDateDifference(startDate,endDate) === 1) {
        return 'Day'
    }
    return 'Days'
}
