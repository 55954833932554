import React, { useEffect } from 'react'
import CategoriesSelect from '../../components/CategoriesSelect'
import DateTimePicker from '../../components/DateTimePicker'
import dayjs from 'dayjs'
import Router, { useRouter } from 'next/router'
import 'react-toggle/style.css'
import FormSeats from './FormSeats'
import FormFuel from './FormFuel'
import FormTransmission from './FormTransmission'
import GooglePlacesInput from '../../components/GooglePlacesInput'
import { useTranslation } from 'next-i18next'
import { getWidgetMode, SearchBoxParameters, setSearchboxParameters } from '../../utils/atoms'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../components/ErrorComponent'
import { useAtom } from 'jotai'
import { searchBoxParametersAtom } from '../../utils/atoms'
import { Fuel, Transmission, VType } from '../../types/backendModels/availability'

interface SearchProps {
    type: VType
}
function Search({ type }:SearchProps) {

    const [searchBoxParameters, setSearchBoxParameters] = useAtom(searchBoxParametersAtom)
    const { t, ready } = useTranslation()
    const router = useRouter()
    const widgetMode = getWidgetMode()
    function setFuel(fuel: Fuel) {
        setSearchboxParameters({ ...searchBoxParameters, fuel: fuel })
    }
    function setCreditCardNeeded(value: boolean) {
        setSearchboxParameters({ ...searchBoxParameters, creditCardNeeded: value })
    }
    function setTransmission(transmissionType: Transmission) {
        setSearchboxParameters({ ...searchBoxParameters, transmissionType: transmissionType })
    }
    function setSeats(seats: number) {
        setSearchboxParameters({ ...searchBoxParameters, seats: seats })
    }
    function setPickupLocation(pickupLocationUpdates) {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            pickupLocation: {
                ...prevState.pickupLocation,
                ...pickupLocationUpdates,
            },
        }))
    }
    function clearReturnLocation() {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            returnLocation: null,
        }))
    }

    function clearPickupLocation() {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            pickupLocation: null,
        }))
    }
    function setReturnLocation(returnLocationUpdates) {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            returnLocation: {
                ...prevState.returnLocation,
                ...returnLocationUpdates,
            },
        }))
    }

    function showReturn() {
        return searchBoxParameters.hasReturnLocation
    }
    function setShowReturn(showReturn: boolean) {
        setSearchboxParameters({ ...searchBoxParameters, hasReturnLocation: showReturn })
    }
    function showAdvancedOptions() {
        return searchBoxParameters.showAdvancedOptions
    }
    function setPickupDateTime(pickupDateTime: Date) {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            pickupDateTime: pickupDateTime,
        }))
    }

    function setReturnDateTime(returnDateTime: Date) {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            returnDateTime: returnDateTime,
        }))
    }
    useEffect(() => {
        ;(async function runEffects() {
            if (searchBoxParameters.hasReturnLocation) {
                setShowReturn(searchBoxParameters.hasReturnLocation)
            }
        })()
    }, [])

    useEffect(() => {
        setSearchBoxParameters({ ...searchBoxParameters, vType: type })
    }, [type])

    const updateSearchBoxParameters = (values: Partial<SearchBoxParameters>) => {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            ...values,
        }))
    }

    const onChangeReturnLocation = () => {
        setShowReturn(!showReturn())
    }

    const resetAdvanceSearch = () => {
        if (!showAdvancedOptions()) {
            updateSearchBoxParameters({
                showAdvancedOptions: true,
            })
        } else {
            updateSearchBoxParameters({
                showAdvancedOptions: false,
                creditCardNeeded: true,
                transmissionType: null,
                seats: 0,
                fuel: null,
            })
        }
    }


    const [isValid, setIsValid] = React.useState(false)

    useEffect(() => {
        setIsValid(
            !!searchBoxParameters.pickupLocation &&
          !!searchBoxParameters.pickupDateTime &&
          !!searchBoxParameters.returnDateTime &&
          (searchBoxParameters.returnLocation ? !!searchBoxParameters.returnLocation : true)
        )
    }, Object.values(searchBoxParameters))


    const search = () => {
        const startDateTime = `start=${dayjs(searchBoxParameters.pickupDateTime).format('YYYY-MM-DDTHH:mm:ss')}`

        const endDateTime = `end=${dayjs(searchBoxParameters.returnDateTime).format('YYYY-MM-DDTHH:mm:ss')}`

        const fuelVal = searchBoxParameters.fuel ? `&fuel=${searchBoxParameters.fuel}` : ''
        const transmissionValue = searchBoxParameters.transmissionType
            ? `&transmission=${searchBoxParameters.transmissionType}`
            : ''
        const seatsVal = searchBoxParameters.seats ? `&seats=${searchBoxParameters.seats}` : ''
        const pickupAddress = searchBoxParameters.pickupLocation.formatted_address.split(' ').join('-')
        const returnAddress = searchBoxParameters.returnLocation
            ? searchBoxParameters.returnLocation.formatted_address.split(' ').join('-')
            : searchBoxParameters.pickupLocation.formatted_address.split(' ').join('-')
        const creditCardVal =
      showAdvancedOptions() && searchBoxParameters.creditCardNeeded === false
          ? '&creditCardNeeded=false'
          : ''

        let urlSearch = widgetMode
            ? `/widget/available/${router.query.rentalId}/${searchBoxParameters.pickupLocation.lng}/${searchBoxParameters.pickupLocation.lat}`
            : `/available/${searchBoxParameters.pickupLocation.lng}/${searchBoxParameters.pickupLocation.lat}`


        const categoryValue = `&category=${searchBoxParameters.vCategory}`
        const vTypeValue =`&vType=${type}`


        if (searchBoxParameters.hasReturnLocation && searchBoxParameters.returnLocation) {
            urlSearch += `/${searchBoxParameters.returnLocation.lng}/${
                searchBoxParameters.returnLocation.lat
            }?pickup=${pickupAddress}&dropoff=${returnAddress}&${startDateTime}&${endDateTime}${categoryValue}${vTypeValue}${transmissionValue}${fuelVal}${seatsVal}${creditCardVal}`
        } else {
            urlSearch += `/${searchBoxParameters.pickupLocation.lng}/${
                searchBoxParameters.pickupLocation.lat
            }?pickup=${pickupAddress}&${startDateTime}&${endDateTime}${categoryValue}${vTypeValue}${transmissionValue}${fuelVal}${seatsVal}${creditCardVal}`
        }

        Router.push(urlSearch).then(
            () => console.debug('Search: Router.push'),
            (error) => console.error('Search: Router.push', error)
        )
    }

    let label = 'Search'

    if (type) {
        if (type === 'Car') {
            label = t('SEARCH CAR')
        }
        if (type === 'Moto') {
            label = t('SEARCH MOTO')
        }
        if (type === 'ATV') {
            label = t('SEARCH ATV')
        }
    }

    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('SearchIndex', error)}>
            <>
                {ready && (
                    <div className="search_container">
                        <GooglePlacesInput
                            pickupLocation={searchBoxParameters.pickupLocation}
                            locationStatus={'pickup'}
                            returnLocation={searchBoxParameters.returnLocation}
                            setLocation={setPickupLocation}
                            setReturnLocation={setReturnLocation}
                            clearPickup={clearPickupLocation}
                            clearReturn={clearReturnLocation}
                        />
                        <div className="checkbox_container">
                            <label htmlFor="dropoff-checkbox">{t('Drop-off at different location')}</label>
                            <input
                                id="dropoff-checkbox"
                                type="checkbox"
                                onChange={onChangeReturnLocation}
                                checked={showReturn()}
                            />
                        </div>
                        {showReturn() && (
                            <GooglePlacesInput
                                returnLocation={searchBoxParameters.returnLocation}
                                locationStatus={'return'}
                                pickupLocation={searchBoxParameters.pickupLocation}
                                setLocation={setPickupLocation}
                                setReturnLocation={setReturnLocation}
                                clearPickup={clearPickupLocation}
                                clearReturn={clearReturnLocation}
                            />
                        )}

                        <CategoriesSelect vType={type} />

                        <DateTimePicker
                            setPickupDateTime={setPickupDateTime}
                            setReturnDateTime={setReturnDateTime}
                            pickupDateTime={searchBoxParameters.pickupDateTime}
                            returnPickupTime={searchBoxParameters.returnDateTime}
                        />
                        <label htmlFor="advanced-search-checkbox" className="checkbox_container">
                            {t('Advanced Search Options')}
                            <input
                                id="advanced-search-checkbox"
                                onChange={() => {
                                    resetAdvanceSearch()
                                }}
                                type="checkbox"
                                defaultChecked={showAdvancedOptions()}
                            />
                        </label>

                        {showAdvancedOptions() && (
                            <>
                                {type === 'Car' && (
                                    <FormTransmission
                                        setTransmission={setTransmission}
                                        transmissionType={searchBoxParameters.transmissionType}
                                    />
                                )}

                                {type === 'Car' && (
                                    <FormSeats setSeats={setSeats} seats={searchBoxParameters.seats} />
                                )}

                                {<FormFuel setFuel={setFuel} fuel={searchBoxParameters.fuel} />}

                                <div className="form-group">
                                    <div className="checkbox_container">{t('Credit Card')} </div>
                                    <div className="checkbox_container">
                                        <input
                                            onChange={(e) => setCreditCardNeeded(!e.target.checked)}
                                            type="checkbox"
                                            value="debit"
                                            defaultChecked={searchBoxParameters.creditCardNeeded === false}
                                        />
                                        {t('Not Needed')}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="button-form-container">
                            <button className="btn btn-primary"
                                disabled={!isValid}
                                onClick={search}>
                                {label}
                            </button>
                        </div>
                    </div>
                )}
            </>
        </ErrorBoundary>
    )
}

export default Search
