/**
 * In backend, this is called `AvailabilityAndPricingFE`
 */
export interface AvailabilityAndPricing {
  availability: Availability
  pricing: Pricing
  parameters: PricingParameters
  price: number
}

export interface Availability {
  rentalId: string
  modelDoc: ModelDoc
  photoUrl?: string
  licenceType: LicenceType
  creditCardNeeded: boolean
  yearsOfLicence: number
  id: string
  notes?: string
}

export interface Pricing {
  locations: LocationPricing[]
  agesAllowed: AgesAllowed
  extras: ExtraPricing[]
  insurances: InsurancePricing[]
  kmsPerDay: KmsPerDay[]
  id: string
}

export interface ModelDoc {
  name: string
  brandName: string
  doors?: number
  seats?: number
  cc?: number
  transmission: Transmission
  fuel: Fuel
  photoUrl?: string
  vType: VType
}

export const ModelDoc ={
    fullName : (model: ModelDoc) => `${model.brandName} - ${model.name}`
}


export type Fuel = 'Benzine' | 'Diesel' | 'LPG' | 'Gas' | 'Electric' | 'Hybrid'

export type Transmission = 'Auto' | 'Manual'

export type VType = 'Car' | 'Moto' | 'ATV'

export const LicenceTypeValues = ['AM', 'A1', 'A2', 'A', 'B', 'BE', 'B1'] as const
export type LicenceType = (typeof LicenceTypeValues)[number]


export interface AgesAllowed {
  from: number
  to: number
}

export interface ExtraPricing {
  extra: Extra
  premiumPerDay?: number
  bookingPrice?: number
}

export type Extra = 'ExtraDriver' | 'GPS' | 'BabySeat' | 'Disabled' | 'RoofRack' | 'Booster'

export const InsuranceTypeValues = [
    'PartInsurance',
    'FullInsurance',
    'PersonalInsuranceCoverage',
    'ThirdPartyLiability',
] as const
export type InsuranceType = (typeof InsuranceTypeValues)[number]

export interface InsurancePricing {
  insurance: Insurance
  premiumPerDay: number
}

export interface Insurance {
  insuranceType: InsuranceType
  excess: number
}


export interface KmsPerDay {
  kmsPerDay: number
  premiumPerDay: number
}

export interface LocationPricing {
  location: RLocationClass
  premiumPerDay: number
}

export interface RLocationClass {
  address: string
  // googlePlaceId: string //TODO to check if this is needed
  id: string
}

export const ProviderValues = [
    'SB',
    'Goldy',
] as const
export type Provider = (typeof ProviderValues)[number];

//FIXME Search with advanced filters
// export interface SearchParameters {
//   locationId: string
//   location: RLocationClass
//   startDateTime: Date
//   endDateTime: Date
//   category: Category
//   transmission: Transmission
//   seats?: number
//   fuel?: Fuel
//   creditCardNeeded: boolean
//   rLocationIndex: string
//   rLocation: RLocationClass
//   ages: number[]
//   extras: Extra[]
//   insurances?: Insurance[]
//   kmsPerDay?: number
//   maxDistanceInMeters: number
// }

// export interface SearchCriteriaApi {
//   location: Coordinates;
//   startDateTime: string;
//   endDateTime: string;
//   category: string;
//   rLocation?: Coordinates;
//   transmission?: Transmission;
//   doors?: number;
//   seats?: number;
//   maxDistanceInMeters?: number;
//   ages?: number[];
//   extras?: Extra[];
//   insurances?: Insurance[];
//   kmsPerDay?: number;
//   creditCardNeeded?: boolean;
//   fuel?: Fuel;
//   rentalId?: string;
//   agencyId?: string;
// }


export interface PricingParameters {
  locationId: string;
  location?: RLocationClass; // READ-ONLY
  startDateTime: string; // ISO 8601 format
  endDateTime: string; // ISO 8601 format
  category: string;
  rLocationId?: string;
  rLocation?: RLocationClass; // READ-ONLY
  ages?: number[];
  extras?: Extra[];
  insurances?: Insurance[]; // can be left empty in POST, always set in GET
  kmsPerDay?: number; // can be left empty in POST, always set in GET
  maxDistanceInMeters: number;
  creditCardNeeded?: boolean;
  provider: Provider;
  rentalId?: string; // BSONObjectID as string
  agencyId?: string; // BSONObjectID as string
}


