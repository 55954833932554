import React, { ReactNode, useEffect, useState } from 'react'

interface LayoutProps {
  children: ReactNode
}

function Layout({ children }: LayoutProps) {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    return <>{isClient && <div>{children}</div>}</>
}

export default Layout
