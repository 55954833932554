import React, { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { createAxiosInstance, mkConfig } from '../axiosInstance'
import useCurrentUrl from '../hooks/useCurrentUrl'
import { useTranslation } from 'next-i18next'
import { SearchBoxParameters, searchBoxParametersAtom } from '../utils/atoms'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { VType } from '../types/backendModels/availability'




interface Props {
    vType: VType
}
const CategoriesSelect = ({ vType }:Props) => {
    const [category, setCategory] = useState(null)
    const [error, setError] = useState(null)
    const [options, setOptions] = useState([{ value: 'Loading...', label: 'Loading...' }])
    const currentUrl = useCurrentUrl()
    const { t, i18n } = useTranslation()
    const [searchBoxParameters,setSearchBoxParameters] = useAtom(searchBoxParametersAtom)
    const capitalizeFirstLetter = (s:string) => {
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const updateSearchBoxParameters = (values: Partial<SearchBoxParameters>) => {
        setSearchBoxParameters((prevState) => ({
            ...prevState,
            ...values,
        }))
    }

    interface OptionType {
        id: string
        value: string
        label: string
        vType: VType
    }

    interface CategoryResponse {
        id:string,
        name:string,
        vType: VType
    }

    const getOptions = useCallback(
        async (vType:VType) => {
            if (!process.env.NEXT_PUBLIC_X_HEADER_AUTH) throw new Error('X_HEADER_AUTH missing')
            const x_header_auth = process.env.NEXT_PUBLIC_X_HEADER_AUTH
            if (!process.env.NEXT_PUBLIC_BACKEND_URL) throw new Error('NEXT_PUBLIC_BACKEND_URL missing')
            const backend_url = process.env.NEXT_PUBLIC_BACKEND_URL

            const url = '/fe/v1/data/categories'

            try {
                const response = await createAxiosInstance(backend_url, x_header_auth).get(
                    url,
                    mkConfig(currentUrl)
                )

                const res = []

                response.data.forEach((obj:CategoryResponse) => {
                    if (obj.vType === vType) {
                        res.push({
                            id: obj.id,
                            value: capitalizeFirstLetter(obj.name),
                            label: t(capitalizeFirstLetter(obj.name)),
                            vType: obj.vType,
                        })
                    }

                    if (vType === 'ATV' && obj.name === 'Quad') {
                        res.push({
                            id: obj.id,
                            value: capitalizeFirstLetter(obj.name),
                            label: t(capitalizeFirstLetter(obj.name)),
                            vType: obj.vType,
                        })
                    }
                })

                res.sort((a, b) => a.rank - b.rank)

                if (res.length > 0) {
                    if (vType === 'Car') {
                        res.unshift({
                            id: 1,
                            value: 'All Cars',
                            label: t('All Cars'), // Translate "All Cars"
                            vType: 'Car',
                        })
                    } else if (vType !== 'ATV') {
                        res.unshift({
                            id: 1,
                            value: 'All Motos',
                            label: t('All Motos'), // Translate "All Motos"
                            vType: 'Moto',
                        })
                    }
                    setCategory(res[0])
                    updateSearchBoxParameters({ vCategory: res[0].value })

                }
                setOptions(res)

                setError(null)
            } catch (error) {
                console.error('categories', error)
                setError('Error connecting to database')
            }
        },
        [t, currentUrl, vType]
    )

    const onChange = (value:OptionType) => {
        if (value !== null) {
            setCategory(value)
            updateSearchBoxParameters({ vCategory: value.value })

        }

    }

    useEffect(() => {
        getOptions(vType).catch(error => {
            console.error('Error fetching categories:', error)
        })
    }, [vType, getOptions, i18n.language])

    const vCategory = _.isEmpty(searchBoxParameters.vCategory)
        ? options[0]
        : {
            label: t(searchBoxParameters.vCategory),
            value: searchBoxParameters.vCategory,
        }

    return (
        <>
            <div className="categories_container">
                <div>
                    <div>
                        <Select
                            value={vCategory}
                            valueKey="id"
                            labelKey="label"
                            onChange={onChange}
                            options={options}
                            className=""
                            aria-label={category?.label}
                            isDisabled={error !== null}
                            placeholder={error === null ? t('All Categories') : t('Error connecting to database')} // Translate placeholder
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoriesSelect
